import TextField from 'client/widget-components/components/TextField';
import { TextCustomizationType } from 'client/widget-components/ecom/stores/types';
import React, { FC, memo } from 'react';
import { ProductCustomizationsStyles } from '../RuntimeProductCustomizationsTypes';
import {
    CharCounter,
    TextareaWithCustomPlaceholder,
} from './TextFieldsCustomComponents';

const TEXT_FIELD_DATA_GRABS = {
    textFieldWrapperDataGrab: 'product-customization-text-field',
    labelDataGrab: 'product-customization-text-field-label',
    textareaDataGrab: 'product-customization-text-field-textarea',
    counterDataGrab: 'product-customization-text-field-counter',
};

const MAX_TEXT_FIELD_LENGTH = 320;

interface RuntimeProductCustomizationTextFieldProps
    extends TextCustomizationType {
    value?: string;
    styles?: ProductCustomizationsStyles;
    onChange: (fieldId: string, value: string) => void;
}

const RuntimeProductCustomizationTextField: FC<
    RuntimeProductCustomizationTextFieldProps
> = ({ id, label, hint, title, value = '', styles, onChange }) => {
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(id, event.target.value.substring(0, MAX_TEXT_FIELD_LENGTH));
    };

    return (
        <TextField
            label={label}
            placeholder={hint}
            styles={{
                label: styles?.inputLabel,
                counter: styles?.inputCharacterCounter,
            }}
            showCounter
            dataAuto='product-customization-text-field'
            dataGrabs={TEXT_FIELD_DATA_GRABS}
            charCounterElement={
                <CharCounter
                    valueLength={value.length}
                    dataGrab={TEXT_FIELD_DATA_GRABS.counterDataGrab}
                    styles={styles?.inputCharacterCounter}
                />
            }
            textareaElement={
                <TextareaWithCustomPlaceholder
                    name={title}
                    id={id}
                    value={value}
                    placeholder={hint}
                    styles={{
                        textarea: styles?.input,
                        placeholder: styles?.inputPlaceholder,
                    }}
                    data-grab={TEXT_FIELD_DATA_GRABS?.textareaDataGrab}
                    increaseSpecificity
                    onChange={handleChange}
                />
            }
        />
    );
};

export default memo(RuntimeProductCustomizationTextField);
