import { RuntimeWidget } from 'client/types';
import { Div } from 'client/widget-components/basicComponents';
import withErrorBoundary from 'client/widget-components/components/ErrorBoundary/withErrorBoundary';
import {
    CustomizationType,
    CustomizationValue,
} from 'client/widget-components/ecom/stores/types';
import React from 'react';
import { RuntimeMediaQuery } from '@duda-co/responsive-styles/RuntimeMediaQuery';
import type { ResponsiveStylesStructured } from '@duda-co/responsive-styles/types/ResponsiveStylesTypes';
import { context } from '@shared-services/context-service';
import { ecomObserver } from '@shared-services/ecom-service';
import { localizationService } from '@shared-services/localization-service';
import RuntimeProductCustomizationTextField from './RuntimeProductCustomizationsFields/RuntimeProductCustomizationTextField';
import {
    ProductCustomizationsProps,
    ProductCustomizationsStyles,
} from './RuntimeProductCustomizationsTypes';

const { str } = localizationService;

const RuntimeProductCustomizations: RuntimeWidget<
    ProductCustomizationsProps
> = ({ productState, _styles: styles }) => {
    if (productState.customizations.length === 0) {
        if (context.isEditor) {
            return (
                <div data-auto='product-customizations-placeholder'>
                    {str(
                        'ui.runtimessr.productCustomizations.noCustomizations'
                    )}
                </div>
            );
        }
        return <div />;
    }

    return (
        <Div
            styles={[wrapperDefaultStyles, styles?.wrapper]}
            data-auto='product-customizations'
        >
            {productState.customizations.map((customization) =>
                getCustomizationField(
                    customization,
                    productState.customizationsValues,
                    styles,
                    productState.updateCustomizationValue
                )
            )}
        </Div>
    );
};

function getCustomizationField(
    customization: CustomizationType,
    customizationsValues: Record<string, CustomizationValue>,
    styles: ProductCustomizationsStyles | undefined,
    updateCustomizationValue: (
        customizationId: string,
        value: CustomizationValue
    ) => void
) {
    // TODO: return the correct field based on the customization type
    return (
        <RuntimeProductCustomizationTextField
            {...customization}
            key={customization.id}
            styles={styles}
            value={customizationsValues[customization.id]}
            onChange={updateCustomizationValue}
        />
    );
}

const wrapperDefaultStyles: ResponsiveStylesStructured = {
    [RuntimeMediaQuery.COMMON]: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
};

const RuntimeProductCustomizationsWidget = withErrorBoundary({
    Comp: ecomObserver(RuntimeProductCustomizations),
    componentName: 'ProductCustomizations',
    logProps: true,
});

export default RuntimeProductCustomizationsWidget;
